<template>
  <div class="select-bg">
    <div class="select-content-bg" v-click-outside="hideModalOptions">
      <div
        :class="[showModal ? 'select-content__open' : '', 'select-content']"
        @click="openModalOptions"
      >
        <div class="select-label">{{ label }}</div>
        <div class="select-content-placeholder" v-if="!value.length">{{ placeholder }}</div>
        <div class="select-content-value-bg" v-if="value.length">
          <div class="select-content-value">
            {{ value[0][itemName] }}
          </div>
          <div class="select-content-count" v-if="value.length > 1">+{{ value.length - 1 }}</div>
        </div>
        <i
          class="icon-up"
          :style="!showModal ? 'transform: rotate(180deg);' : ''"
          v-show="(value.length <= 1 && multiple) || (value.length < 1 && !multiple)"
        ></i>
        <i
          class="icon-cross"
          v-show="(value.length > 1 && multiple) || (value.length >= 1 && !multiple)"
          @click.self="deleteAllOption"
        ></i>
      </div>
      <ModalSelect
        v-if="showModal"
        :value="value"
        :placeholder="placeholder"
        :itemName="itemName"
        :entity="entity"
        :maxCount="maxCount"
        :multiple="multiple"
        :actionEntity="actionEntity"
        :hiddenItems="hiddenItems"
        :createMode="createMode"
        :createEntity="createEntity ? createEntity : 'actionCreate'"
        :channelUsers="channelUsers"
        :is_deleted="is_deleted"
        :filterType="filterType"
        :filterActive="filterActive"
        @pushOptionToValue="pushOptionToValue"
        @deleteOptionToValue="deleteOptionToValue"
        @pushAllOption="pushAllOption"
        :isAdminRegulation="isAdminRegulation"
        :isRegulation="isRegulation"
        :ispGroup="ispGroup"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
const ModalSelect = () => import("@/components/Widgets/Select/Select2.0/ModalSelect");

export default {
  name: "Select2.0",
  mixins: [],
  props: {
    filterActive: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    editValue: {
      type: Array,
      default: () => {
        return [];
      }
    },
    keyValue: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    class: {
      type: String,
      default: ""
    },
    itemName: {
      type: String,
      default: ""
    },
    entity: {
      type: String,
      default: ""
    },
    actionEntity: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxCount: {
      type: Number
    },
    createMode: {
      type: Boolean
    },
    hiddenItems: {
      type: Array,
      default: () => {
        return [];
      }
    },
    createEntity: {
      type: String
    },
    channelUsers: {
      type: Array
    },
    is_deleted: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    filterType: {
      type: Array
    },
    isAdminRegulation: {
      type: Boolean
    },
    isRegulation: {
      type: Boolean
    },
    ispGroup: {
      type: Boolean
    }
  },
  components: { ModalSelect },
  data() {
    return {
      value: [],
      showModal: false
    };
  },
  beforeDestroy() {
    this.$root.$off("hideSelectModal");
    this.$root.$off(`insertValue${this.keyValue}`);
  },
  mounted() {
    if (this.editValue && this.editValue.length) {
      this.value = this.editValue;
    }
    this.$root.$on("hideSelectModal", () => {
      this.showModal = false;
    });
    this.$root.$on(`insertValue${this.keyValue}`, (val) => {
      this.value = val;
    });
    this.$root.$on("deleteAllOptionsSelect", () => {
      this.deleteAllOption();
    });
  },
  methods: {
    openModalOptions() {
      if (!this.disabled) {
        this.showModal = !this.showModal;
      } else {
        return false;
      }
    },
    hideModalOptions() {
      this.showModal = false;
    },
    deleteAllOption() {
      if (!this.disabled) {
        event.stopPropagation();
        this.value = [];
      } else {
        return false;
      }
      this.$emit("selectOption", this.value);
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    },
    pushAllOption(options) {
      this.value = JSON.parse(JSON.stringify(options));
      if (this.hiddenItems.length) {
        for (let i in this.hiddenItems) {
          let index = this.value.findIndex((val) => val.id === this.hiddenItems[i].id);
          if (index !== -1) this.value.splice(index, 1);
        }
      }
      this.$emit("selectOption", this.value);
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    },
    pushOptionToValue(option) {
      if (this.multiple) {
        if (this.maxCount) {
          if (this.value.length < this.maxCount) {
            this.value.push(option);
          } else {
            this.$root.$emit("showNotification", {
              type: "error",
              timeout: 3000,
              label: `Максимальное кол-во ${this.maxCount} !`
            });
          }
        } else {
          this.value.push(option);
        }
      } else {
        if (this.value.length) {
          this.value.splice(0, 1);
          this.value.push(option);
          this.showModal = false;
        } else {
          this.value.push(option);
          this.showModal = false;
        }
      }
      this.$emit("selectOption", this.value);
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    },
    deleteOptionToValue(option) {
      this.value = this.value.filter((val) => val.id !== option.id);
      this.$emit("selectOption", this.value);
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    }
  },
  computed: {},
  watch: {
    editValue() {
      this.value = this.editValue;
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.icon-cross,
.icon-up {
  margin-left: auto;
  color: $icon-subdued;
  font-size: 16px;
  transition: 0.3s;
}

.select-bg {
  width: 200px;

  .select-label {
    margin-right: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $text-subdued;
  }

  .select-content-bg {
    position: relative;
    width: 100%;
    height: 48px;

    .select-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      background: #fafbfc;
      border-radius: 24px;
      cursor: pointer;

      &__open {
        border-radius: 24px 24px 0 0;
      }

      .select-content-placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-disabled;
      }

      .select-content-value-bg {
        display: flex;
        align-items: center;
        width: calc(100% - 120px);
        flex-shrink: 0;

        .select-content-value {
          font-weight: normal;
          font-size: 14px;
          line-height: 23px;
          color: $text-default;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 100%;
        }

        .select-content-count {
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: $text-default;
          margin: 0 6px 0 4px;
          background: #c1c7d0;
          padding: 2px 4px;
          border-radius: 14px;
        }
      }
    }
  }
}
</style>
